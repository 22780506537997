

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* *******************     my css      ************************** */
.border-bottom-centered::after, .border-bottom::after{
  content: "";
  width: 10%;
  height: 10px;
  background-color: #EC2C6D;
  border-radius: 10px;
  display: block;
}
.border-bottom::after{
  margin: 10px 0 0;
}
.border-bottom-centered::after{
  margin: 10px auto 0;
}
.font{
  font-family: Inter sans-serif;
}


.readmore-list{
  list-style: none;
  display: flex;
  align-items: center;
  margin-top: 3vh;
}
.readmore-list::before {
  content: "\2022";
  color: #EC2C6D;  
  font-size: 4rem;
  margin-right: 8px;
}


#video-link{
  width: clamp(200px, 20vw, 500px);
  height: clamp(180px, 25vh, 300px);
}
@media screen and (max-width: 900px) {
  #video-link {
    width: clamp(180px, 20vw, 450px) ; /* Adjust as needed based on your layout */
    height: clamp(100px, 13vh, 300px); /* Maintain the aspect ratio */
    /* Add any additional styles for smaller screens */
  }
  
}
/********************************* banner animations **********************/



.banner-anm-img{
  position: absolute;
  top: 10%;
  left: 0%;
  width: clamp(300px, 100%, 600px);
}

#banner-anm-img1{
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  animation-name: banner-phone-road-anm;
  animation-delay: 1s;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}
#banner-anm-img2{
  z-index: 2;
  opacity: 0;
  visibility: hidden;
  animation-name: banner-phone-road-anm;
  animation-delay: 2s;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}
#banner-anm-img3{
  z-index: 3;
  opacity: 0;
  visibility: hidden;
  animation-name: banner-road-line-anm;
  animation-delay: 2.5s;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}
#banner-anm-img4{
  z-index: 4;
  opacity: 0;
  visibility: hidden;
  animation-name: banner-buildings-location-circles-anm;
  animation-delay: 2.5s;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}
#banner-anm-img5{
  z-index: 5;
  opacity: 0;
  visibility: hidden;
  animation-name: banner-buildings-location-circles-anm;
  animation-delay: 2.5s;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}
#banner-anm-img6{
  z-index: 6;
  opacity: 0;
  visibility: hidden;
  animation-name: banner-buildings-location-circles-anm;
  animation-delay: 3s;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}
@keyframes banner-phone-road-anm {

  to{
    opacity: 1;
    visibility: visible;
  }
}
@keyframes banner-road-line-anm {
  0% {
    opacity: 0;
    visibility: hidden;
    transform: translateX(-10px);
  }
  50%{
    opacity: 1;
    visibility: visible;
    transform: translateX(10px);
  }
  100%{
    opacity: 1;
    visibility: visible;
    transform: translateX(0px);
  }
}
@keyframes banner-buildings-location-circles-anm {
  0% {
    opacity: 0;
    visibility: visible;
    transform: translateY(-10px);
  }
  50%{
    opacity: 1;
    visibility: visible;
    transform: translateY(10px);
  }
  100%{
    opacity: 1;
    visibility: visible;
    transform: translateY(0px);
  }
}




/********************************* our story animations **********************/


.our-story-bg-anm{
  position: absolute;
  opacity: 0;
  visibility: hidden;
  top: 0%;
  right: 0%;
  width: clamp(300px, 100%, 400px);
}

#our-story-bg-anm-1{
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  animation-name: map-persons-heart;
  animation-delay: 1s;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}
#our-story-bg-anm-2{
  z-index: 2;
  opacity: 0;
  visibility: hidden;
  animation-name: map-house;
  animation-delay: 1.5s;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}
#our-story-bg-anm-3{
  z-index: 3;
  opacity: 0;
  visibility: hidden;
  animation-name: map-persons-heart;
  animation-delay: 2.5s;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
}

@keyframes map-persons-heart {
  to{
    opacity: 1;
    visibility: visible;
  }
}
@keyframes map-house {
  from{
    opacity: 1;
    visibility: visible;
    transform: translate(50px, -50px);
  }
  to{
    opacity: 1;
    visibility: visible;
    transform: translate(0, 0);
  }
}


/*********** our story second bg animation****************************/

.our-story-soceond-bg-anm{
  position: absolute;
  opacity: 0;
  visibility: hidden;
  top: 0;
  left: 0%;
  width: clamp(300px, 100%, 400px);
}

#our-story-soceond-bg-anm-1{
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  animation-name: person-house1-msg1-dialogue-house2;
  animation-delay: 1s;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}
#our-story-soceond-bg-anm-2{
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  animation-name: person-house1-msg1-dialogue-house2;
  animation-delay: 1.5s;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}
#our-story-soceond-bg-anm-3{
  z-index: 2;
  opacity: 0;
  visibility: hidden;
  animation-name: person-house1-msg1-dialogue-house2;
  animation-delay: 2s;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}
#our-story-soceond-bg-anm-4{
  z-index: 3;
  opacity: 0;
  visibility: hidden;
  animation-name: msg2;
  animation-delay: 2.5s;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}
#our-story-soceond-bg-anm-5{
  z-index: 4;
  opacity: 0;
  visibility: hidden;
  animation-name: person-house1-msg1-dialogue-house2;
  animation-delay: 2.5s;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}
#our-story-soceond-bg-anm-6{
  z-index: 5;
  opacity: 0;
  visibility: hidden;
  animation-name: person-house1-msg1-dialogue-house2;
  animation-delay: 3s;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

@keyframes person-house1-msg1-dialogue-house2 {
  to{
    opacity: 1;
    visibility: visible;
  }
}
@keyframes msg2 {
  from{
    opacity: 0;
    visibility: hidden;
    transform: translate(-10px, 10px);
  }
  to{
    opacity: 1;
    visibility: visible;
    transform: translate(0px, 0px);
  }
}


/*********** our story second bg animation****************************/


.pricing-bg-anm{
  position: absolute;
  opacity: 0;
  visibility: hidden;
  top: 0%;
  left: 0%;
  width: clamp(300px, 70vw, 900px);
}

#pricing-bg-anm-1{
  z-index: 3;
  opacity: 0;
  visibility: hidden;
  animation-name: pricing-phone1-phone2;
  animation-delay: 1s;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}
#pricing-bg-anm-2{
  z-index: 2;
  opacity: 0;
  visibility: hidden;
  animation-name: pricing-phone3;
  animation-delay: 2s;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}
#pricing-bg-anm-3{
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  animation-name: pricing-phone1-phone2;
  animation-delay: 2s;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}
#pricing-bg-anm-4{
  z-index: 4;
  opacity: 0;
  visibility: hidden;
  animation-name: pricing-phone1-phone2;
  animation-delay: 3.5s;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}
#pricing-bg-anm-5{
  z-index: 5;
  opacity: 0;
  visibility: hidden;
  animation-name: pricing-keys;
  animation-delay: 4s;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}


@keyframes pricing-phone1-phone2 {
  to{
    opacity: 1;
    visibility: visible;
  }
}
@keyframes pricing-phone3 {
  0%{
    opacity: 1;
    visibility: visible;
    transform: translate(100px, 0);
    transform: rotateX(0deg);
  }
  25%{
    opacity: 1;
    visibility: visible;
    transform: translate(-100px, 30px);
    transform: rotateX(30deg);
  }
  50%{
    opacity: 1;
    visibility: visible;
    transform: translate(0px, 0px);
    transform: rotateX(0deg);
  }
  75%{
    opacity: 1;
    visibility: visible;
    transform: translate(-50px, 10px);
    transform: rotateX(10deg);
  }
  100%{
    opacity: 1;
    visibility: visible;
    transform: translate(0px, 0px);
    transform: rotateX(0deg);
  }
  
}
@keyframes pricing-keys {
  from{
    opacity: 1;
    visibility: visible;
    transform: translate(-30%, 30%);
  }
  to{
    opacity: 1;
    visibility: visible;
    transform: translate(0%, 0%);
  }
}


/* ****************************************  pricing second bg animation  ********************************************************** */



.pricing-second-bg-anm{
    position: absolute;
    opacity: 0;
    visibility: hidden;
    top: 0%;
    right: 0;
    width: clamp(300px, 50vw, 400px);
}

#pricing-second-bg-anm-1{
  z-index: 2;
  opacity: 0;
  visibility: hidden;
  animation-name: pricing-second-phone;
  animation-delay: 1s;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}
#pricing-second-bg-anm-2{
  z-index: 3;
  opacity: 0;
  visibility: hidden;
  animation-name: pricing-second-phone;
  animation-delay: 2s;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}
#pricing-second-bg-anm-3{
  z-index: 5;
  opacity: 0;
  visibility: hidden;
  animation-name: pricing-second-phone;
  animation-delay: 7s;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}
#pricing-second-bg-anm-4{
  z-index: 4;
  opacity: 0;
  visibility: hidden;
  animation-name: pricing-second-phone;
  animation-delay: 4s;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}
#pricing-second-bg-anm-5{
  z-index: 5;
  opacity: 0;
  visibility: hidden;
  animation-name: pricing-second-phone;
  animation-delay: 5s;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}
#pricing-second-bg-anm-6{
  z-index: 6;
  opacity: 0;
  visibility: hidden;
  animation-name: pricing-second-phone;
  animation-delay: 6s;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}
#pricing-second-bg-anm-7{
  z-index: 7;
  opacity: 0;
  visibility: hidden;
  animation-name: pricing-second-phone;
  animation-delay: 7s;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}
#pricing-second-bg-anm-8{
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  animation-name: pricing-second-msg-tick;
  animation-delay: 2s;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

@keyframes pricing-second-phone {
  to{
    opacity: 1;
    visibility: visible;
  }
}

@keyframes pricing-second-msg-tick {
  0%{
    opacity: 1;
    visibility: visible;
    transform: translate(0%, -10%);
  }
  50%{
    opacity: 1;
    visibility: visible;
    transform: translate(0%, 10%);
  }
  100%{
    opacity: 1;
    visibility: visible;
    transform: translate(0%, 0%);
  }
}



/* ************************************     benefits bg animation         ******************************************** */


.benefits-second-bg-anm{
  position: absolute;
  top: 0%;
  left: 0%;
  width: clamp(300px, 100%, 900px);
}

#benefits-second-bg-anm-1{
  z-index: 1;
}
#benefits-second-bg-anm-2{
  z-index: 2;
  animation-name: benefits-person-phone;
  animation-delay: 1s;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}
#benefits-second-bg-anm-3{
  z-index: 3;
  animation-name: benefits-person-phone;
  animation-delay: 1s;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}
#benefits-second-bg-anm-4{
  z-index: 6;
  opacity: 0;
  visibility: hidden;
  animation-name: benefits-person2;
  animation-delay: 1.5s;
  animation-duration: 4s;
  animation-timing-function: cubic-bezier(.13,1.46,.61,.87);
  animation-fill-mode: forwards;
}
#benefits-second-bg-anm-5{
  z-index: 7;
  opacity: 0;
  visibility: hidden;
  animation-name: benefits-key;
  animation-delay: 3s;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}
#benefits-second-bg-anm-6{
  z-index: 8;
  opacity: 0;
  visibility: hidden;
  animation-name: benefits-person3;
  animation-delay: 3.5s;
  animation-timing-function:  cubic-bezier(.13,1.46,.61,.87);
  animation-duration: 1s;
  animation-fill-mode: forwards;
}
#benefits-second-bg-anm-7{
  z-index: 4;
  opacity: 0;
  visibility: hidden;
  animation-name: benefits-person-phone-reverse;
  animation-delay: 1s;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}
#benefits-second-bg-anm-8{
  z-index: 5;
  opacity: 0;
  visibility: hidden;
  animation-name: benefits-person-phone-reverse;
  animation-delay: 1s;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}


@keyframes benefits-person-phone {
  from{
    opacity: 1;
    visibility: visible;
    transform: translate(0, 0);
  }
  to{
    opacity: 0;
    visibility: hidden;
    transform: translate(10%, 100%);
  }
}
@keyframes benefits-person-phone-reverse {
  from{
    opacity: 0;
    visibility: hidden;
    transform: translate(-20%, 100%);
  }
  to{
    opacity: 1;
    visibility: visible;
    transform: translate(0%, 0%);
  }
}

@keyframes benefits-person2 {
  0%{
    opacity: 0;
    visibility: hidden;
    transform: translate(-20%, 0);
    height: 0;
  }
  50%{
    opacity: 1;
    visibility: visible;
    transform: translate(0, 0);
    height: 100%;
  }
  100%{
    opacity: 0;
    visibility: hidden;
    transform: translate(0, 0);
    height: 100%;
  }
  
}

@keyframes benefits-key {
  0%{
    opacity: 1;
    visibility: visible;
    transform: rotateX(30deg);
  }
  25%{
    opacity: 1;
    visibility: visible;
    transform: rotateX(-30deg);
  }
  50%{
    opacity: 1;
    visibility: visible;
    transform: rotateX(30deg);
  }
  75%{
    opacity: 1;
    visibility: visible;
    transform: rotateX(-30deg);
  }
  100%{
    opacity: 1;
    visibility: visible;
    transform: rotateX(0deg);
  }
  
}

@keyframes benefits-person3 {
  from{
    opacity: 0;
    visibility: hidden;
    transform: translate(10%, 0%);
  }
  to{
    opacity: 1;
    visibility: visible;
    transform: translate(0%, 0%);
  }
}



/* ************************************     contact bg animation         ******************************************** */



.contact-bg-anm{
  position: absolute;
  top: 0;
  left: 0%;
  width: clamp(300px, 50vw, 400px);
}

#contact-bg-anm-1{
  z-index: 1;
}

#contact-bg-anm-2{
  z-index: 2;
  animation-name: contact-bulb;
  animation-delay: 1s;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

#contact-bg-anm-3{
  z-index: 5;
  opacity: 0;
  visibility: hidden;
  animation-name: contact-house1-house2-fan-clouds-bulb2-bulb3, contact-house1-house2-fan-clouds-bulb2-bulb3-bounce;
  animation-delay: 1s, 5s;
  animation-duration: 1s, 0.3s;
  animation-fill-mode: forwards;
}

#contact-bg-anm-4{
  z-index: 3;
  opacity: 0;
  visibility: hidden;
  animation-name: contact-house1-house2-fan-clouds-bulb2-bulb3, contact-house1-house2-fan-clouds-bulb2-bulb3-bounce;
  animation-delay: 3s, 5s;
  animation-duration: 1s, 0.3s;
  animation-fill-mode: forwards;
}

#contact-bg-anm-5{
  z-index: 3;
  opacity: 0;
  visibility: hidden;
  animation-name: contact-house1-house2-fan-clouds-bulb2-bulb3, contact-house1-house2-fan-clouds-bulb2-bulb3-bounce;
  animation-delay: 2s, 5s;
  animation-duration: 1s, 0.3s;
  animation-fill-mode: forwards;
}


#contact-bg-anm-6{
  z-index: 4;
  opacity: 0;
  visibility: hidden;
  animation-name: contact-house1-house2-fan-clouds-bulb2-bulb3;
  animation-delay: 3s;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

#contact-bg-anm-7{
  z-index: 3;
  opacity: 0;
  visibility: hidden;
  animation-name: contact-house1-house2-fan-clouds-bulb2-bulb3, contact-house1-house2-fan-clouds-bulb2-bulb3-bounce;
  animation-delay: 4s, 5s;
  animation-duration: 1s, 0.3s;
  animation-fill-mode: forwards;
}

#contact-bg-anm-8{
  z-index: 4;
  opacity: 0;
  visibility: hidden;
  animation-name: contact-house1-house2-fan-clouds-bulb2-bulb3, contact-house1-house2-fan-clouds-bulb2-bulb3-bounce;
  animation-delay: 4s, 5s;
  animation-duration: 1s, 0.3s;
  animation-fill-mode: forwards;
}


@keyframes contact-house1-house2-fan-clouds-bulb2-bulb3 {
  from{
    opacity: 0;
    visibility: hidden;
  }
  to{
    opacity: 1;
    visibility: visible;
  }
}
@keyframes contact-house1-house2-fan-clouds-bulb2-bulb3-bounce {
  0%{
    opacity: 1;
    visibility: visible;
    transform: translate(0%, 0%);
  }
  25%{
    opacity: 1;
    visibility: visible;
    transform: translate(-3%, 3%);
  }
  50%{
    opacity: 1;
    visibility: visible;
    transform: translate(3%, -3%);
  }
  75%{
    opacity: 1;
    visibility: visible;
    transform: translate(-3%, 3%);
  }
  100%{
    opacity: 1;
    visibility: visible;
    transform: translate(0%, 0%);
  }
}