.text-animation {
  display: inline-block;
}

.animated-word {
  /* display: inline-block; */
  animation: bounce 0.5s linear;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-10px);
  }
}
